/* eslint-disable no-param-reassign */
import axios, { AxiosInstance } from 'axios';
import urls from 'configs/urls';
import { getPartnerName } from 'partner_configuration';
import { getLocalStorageItem } from 'utils/commonFunctions';

/**
 * Create multiple axios instances with different base URLs for easier
 * communication with different services.
 * Base URLs are dependent on current environment.
 * */

const babyPortalApi: AxiosInstance = axios.create({
  baseURL: urls.baseUrl,
  headers: {
    'Content-Type': 'application/json'
  }
});

babyPortalApi.interceptors.request.use(async (request) => {
  if (request && request.headers) {
    if (!request.headers['skip-auth']) {
      // Add Authorization, Partner-ID, and Client-ID headers if 'skip-auth' is not set
      request.headers['Auth-Key'] = getLocalStorageItem('auth-token');
    }
    const url = window.location.origin;
    console.log('url::', url);
    const partnerName = getPartnerName(url);
    console.log(':: partnerName', partnerName);
    request.baseURL = urls.baseUrl;
    request.headers['Partner-ID'] = partnerName;
    request.headers['Client-ID'] = 'web';
  }
  return request;
});

babyPortalApi.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Check if error.response exists before accessing its properties
    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        // Add refresh token logic here
        // Example: await refreshAccessToken();
      }

      if (error.response.status === 403) {
        console.error('403 Forbidden error:', error.response.data);
        // Handle forbidden access case here
      }
    } else {
      console.error('Network or CORS error:', error.message);
    }

    return Promise.reject(error); // Reject with error to maintain promise chain
  }
);

export { babyPortalApi };
