/* eslint-disable no-unneeded-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { CURRENCY, FREE_TRIAL_DURATION } from 'constants/constants';
import Button from '@mui/material/Button';
import { babyPortalApi } from 'data/axios';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Support from 'assets/24-support.svg';
import Adfree from 'assets/ad-free.svg';
import Breath from 'assets/breathing-monitoring.svg';
import CameraIcon from 'assets/camera_icon.png';
import CloudStorage from 'assets/cloud-storage.svg';
import Cry from 'assets/crying-baby.svg';
import Sharing from 'assets/people.svg';
import RibbonLunaIcon from 'assets/ribbon-luna.svg';
import RibbonIcon from 'assets/ribbon.svg';
import SleepAlert from 'assets/sleep-alert.svg';
import SleepTrack from 'assets/sleep-tracking.svg';
import house from 'assets/smart_home.png';
import VideoCameraIcon from 'assets/video-camera.svg';
import { IsMobile } from 'hooks/isMobile';
import styled from 'styled-components';
import { Flex, Image } from 'styles';

import DiscountArrow from 'assets/discount-arrow.png';
import { GetPlanDetails, Plans } from 'services/api/plansApi';
import {
  CheckFreeTrialSubscription
  // SubscriptionType
} from 'services/api/subscriptionApi';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateFreeTrialEligibility } from 'store/reducers/userReducers';

import IOSSwitch from 'components/IOSSwitch';
import Spinner from 'components/Spinner';

import {
  CircularProgress,
  Dialog,
  Stack,
  styled as styledMUI,
  Typography,
  useTheme
} from '@mui/material';
import { PARTNER } from 'partner_configuration/config';
import { endpoints } from 'services/api/endpoints';
import { getPartnerId } from 'utils/commonFunctions';

export interface IPlan {
  id: string;
  name: string;
  description: string[];
  type: string;
  trial_period: number;
  subscription_cycle: number;
  data_retention_days: number;
  level: number;
  supported_camera_count: number;
  prices: IPrice[];
  supported_features: IFeature[];
}

export interface IFeature {
  id: string;
  description: string;
}

export interface IPrice {
  country: string;
  currency: string;
  price: number;
  stripe_price_id: string;
  original_price: number;
}

const Heading = styled.div`
  color: #494141;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
  text-transform: capitalize;
`;

const PlanCard = styled.div`
  position: relative;
  min-width: 236px;
  border-radius: 24px;
  border: 1.5px solid #dddfdf;
  background: #f0f4f4;
  margin-right: 10px;
  padding: 16px;
  align-self: stretch;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const PlanHeading = styled.div`
  color: ${(props) => props.theme.palette.primary.main};
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-top: 8px;
  margin-bottom: 4px;
`;

const PlanDetailPoint = styled.div`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-left: 10px;
`;

const PlanType = styled.div<{ active?: boolean }>`
  color: #494141;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  opacity: ${(props) => (props.active ? 0.3 : 1)};
`;

const DiscountInfo = styled.div`
  color: #fe832d;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  position: relative;
  margin-top: 5px;
`;

const DialogHeading = styled.div`
  color: #000;
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.01em;
`;

const DialogSubHeading = styled.div`
  color: #484c4f;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
`;

const ImgCntr = styledMUI('img')`
  position: absolute;
  left: 50%;
  margin-top: -36px;
  height: 40px;
  // transform: translate(-50%, -50%);
  transform: translateX(-50%);
`;

const PaymentContainer = styledMUI('div')`
  display: block;
`;

const GreyText = styledMUI('span')`
  color: rgba(0, 0, 0, 0.4);
`;

const FlexContainer = styled.div``;

type IProps = {
  onPlanSelection?: (plan: IPlan) => void;
  isFreeTrial?: boolean;
  isUpgrade?: boolean;
  deviceId?: string;
  pairingFlow?: boolean;
  selectedSubscription?: any;
};

type IIconMap = {
  [key: string]: string;
};

export interface PlanList {
  id: string;
  name: string;
  description: string[];
  supported_features: SupportedFeature[];
  type: string;
  trial_period: number;
  subscription_cycle: number;
  data_retention_days: number;
  supported_camera_count: number;
  level: number;
  prices: Price[];
}

export interface SupportedFeature {
  id: string;
  description: string;
}

export interface Price {
  country: string;
  currency: string;
  original_price: number;
  price: number;
  stripe_price_id: string;
}

const obj: IIconMap = {
  basic_features: CameraIcon,
  user_sharing: Sharing,
  '30_days_activity': CloudStorage,
  enhanced_support: Support,
  smart_home: house,
  cry_detection: Cry,
  sleep_tracking: SleepTrack,
  sleep_alerts: SleepAlert,
  breathing_monitoring: Breath,
  ad_free: Adfree,
  unlimited_camera_supported: VideoCameraIcon,
  '1_camera_supported': VideoCameraIcon
};

type IPurchasePayload = {
  country: string;
  sku_id: string;
  trial: boolean;
  action?: string;
  previous_purchase_id?: string;
};

export function SubscriptionPlanContent(props: IProps) {
  const {
    onPlanSelection,
    isFreeTrial,
    isUpgrade,
    deviceId,
    pairingFlow,
    selectedSubscription
  } = props;

  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // debugger;
  const [allPlans, setAllPlans] = useState<Plans[]>([]);
  const [checked, setChecked] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [showSkipDialog, toggleShowSkipDialog] = useState(false);
  const [canPurchaseFreeTrial, toggleCanPurchaseFreeTrial] = useState(false);

  const subscriptionData = useAppSelector<any[]>(
    (state) => state.subscription.subscriptionData
  );

  const currentSubscription = selectedSubscription
    ? selectedSubscription
    : subscriptionData[0];

  // debugger;
  console.log(
    'SubscriptionPlanContent -- currentSubscription ',
    currentSubscription
  );

  // eslint-disable-next-line no-unneeded-ternary
  // const canPurchaseFreeTrial = isFreeTrial ? isFreeTrial : isEligibleForTrial;
  // const canPurchaseFreeTrial = isEligibleForTrial;
  console.log('canPurchaseFreeTrial ', canPurchaseFreeTrial);

  // const devicePresentInSubscription =
  //   subscriptionData.length > 0 && subscriptionData[0].devices.length > 0;

  const isActiveSubscription =
    currentSubscription &&
    currentSubscription.purchase_id &&
    currentSubscription.subscribe_status === 'Active';

  console.log(
    'SubscriptionPlanContent -- isActiveSubscription ',
    isActiveSubscription
  );

  const isActiveYearlySubscription =
    isActiveSubscription && currentSubscription.subscription_cycle === 12;

  // const isEligibleForUpgrade =
  //   isActiveSubscription && subscriptionData[0].subscription_cycle !== 12;

  // console.log('isEligibleForUpgrade ', isEligibleForUpgrade);

  // eslint-disable-next-line no-unneeded-ternary
  const canPurchaseUpgrade = isUpgrade; // ? isUpgrade : isEligibleForUpgrade;
  console.log(
    'SubscriptionPlanContent -- canPurchaseUpgrade ',
    canPurchaseUpgrade
  );

  const isAnyActiveNonLinkedSubscription = subscriptionData.find(
    (item) =>
      item.purchase_id &&
      item.subscribe_status === 'Active' &&
      item.devices.length === 0
  );

  // debugger;
  const partnerName = getPartnerId();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([GetPlanDetails(), CheckFreeTrialSubscription()])
      .then((res) => {
        const [allPlan, freeTrialEligibility] = res;
        // debugger;
        setAllPlans(allPlan.data);
        toggleCanPurchaseFreeTrial(freeTrialEligibility.data.trial);

        dispatch(updateFreeTrialEligibility(freeTrialEligibility.data.trial));

        console.log('setting All plans');
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleChange = () => {
    setChecked(!checked);
  };

  const findCurrentPlanLevel = () => {
    let curretPlanLevel = 0;
    // checking for level wrt current subscription
    allPlans?.forEach((el: IPlan) => {
      if (el?.id === currentSubscription?.sku_id) {
        curretPlanLevel = Number(el.level);
      }
    });
    return curretPlanLevel;
  };

  if (isLoading) {
    return <Spinner />;
  }

  const disableSelection = false; //

  const curretPlanLevel = currentSubscription ? findCurrentPlanLevel() : 0;

  const filterByLevel = (el: IPlan) => el.level >= curretPlanLevel;

  console.log('allPlans -- ', allPlans);

  const yearlyPlans = allPlans.filter(
    (el: IPlan) =>
      el.subscription_cycle === 12 &&
      (disableSelection ? true : filterByLevel(el))
  );
  const monthlyPlans = allPlans.filter(
    (el: IPlan) =>
      el.subscription_cycle === 1 &&
      (disableSelection ? true : filterByLevel(el))
  );

  const typeOfPlans = checked ? yearlyPlans : monthlyPlans;

  const createPurchaseId = (plan: IPlan) => {
    const payload: IPurchasePayload = {
      country: 'US',
      sku_id: plan.id,
      trial: canPurchaseFreeTrial
    };
    // debugger;
    if (canPurchaseUpgrade && currentSubscription) {
      payload['action'] = 'Upgrade';
      payload['previous_purchase_id'] = currentSubscription.purchase_id;
    }
    // debugger;
    babyPortalApi
      .post(endpoints.createPurchaseIdUrl, payload)
      .then((res) => {
        // debugger;
        if (isUpgrade) {
          navigate('/payment-selections', {
            state: {
              isUpgrade: canPurchaseUpgrade,
              isFreeTrial: canPurchaseFreeTrial,
              planSelected: plan,
              purchaseId: res.data.purchase_id,
              deviceId,
              dontAddDevice: deviceId ? false : true,
              oldSubscription: currentSubscription
            }
          });
        } else {
          navigate('/selectDevice', {
            state: {
              isUpgrade,
              isFreeTrial: canPurchaseFreeTrial,
              planSelected: plan,
              purchaseId: res.data.purchase_id,
              deviceId,
              dontAddDevice: deviceId ? false : true,
              oldSubscription: currentSubscription
            }
          });
        }
      })
      .catch((err) => console.log('purchases err', err));
  };

  const getSupportedCameraCount = (supportedFeatures: any) => {
    const cameraSupportedCount = supportedFeatures
      .find((item: any) => item.id.includes('camera_supported'))
      ?.id.split('_')[0];

    return cameraSupportedCount === 'unlimited' ? 99 : 1;
  };

  const addCameraToSubscription = async (subscriptionObj: any) => {
    // debugger;

    const supportedCameraCount = getSupportedCameraCount(
      subscriptionObj.supported_features
    );
    let payload = {};
    // debugger;

    if (supportedCameraCount > 1) {
      // debugger;
      const deviceArray = subscriptionObj.map((el: any) => el?.device_id);
      const device_ids = deviceArray;
      device_ids.push(deviceId);

      // debugger;
      payload = {
        device_ids
      };
    } else {
      payload = {
        device_ids: [deviceId]
      };
    }

    let errorMsg = '';
    // debugger;
    babyPortalApi
      .post(
        `/api/v1/parents/subscriptions/${subscriptionObj.purchase_id}/devices`,
        payload
      )
      .then((res) => {
        console.log('addCameraToSubscription success', res);
        // dispatch(fetchAllSubscription());
        // debugger;
      })
      .catch((err) => {
        console.log('addCameraToSubscription err->>', err);
        // debugger;

        errorMsg =
          'Sorry! We cannot add the device to your selected to your subscription plan';
        // setErrorMessage(
        //   'Sorry! We cannot add the device to your selected to your subscription plan'
        // );
        console.log('addCameraToSubscription', err);
      })
      .finally(() => {
        setIsLoading(false);
        // setPurchaseStatus(true);
        // debugger;

        navigate('/payment-success', {
          state: {
            isFreeTrial,
            isUpgrade,
            errorMessage: errorMsg,
            showSetupComplete: Boolean(deviceId),
            deviceId,
            purchaseId: subscriptionObj.purchase_id
          }
        });
      });
  };

  const handlePlanSelection = (el: IPlan) => {
    console.log('handlePlanSelection', el);
    // debugger;

    if (!pairingFlow && isAnyActiveNonLinkedSubscription && deviceId) {
      // debugger;
      addCameraToSubscription(isAnyActiveNonLinkedSubscription);
    } else {
      // debugger;
      createPurchaseId(el);
    }
  };

  /**
   * ShowToogle will remove the toggle switch if number of plans after level filter for yeraly/monthly is zero
   */
  const showToggle = !(yearlyPlans.length === 0 || monthlyPlans.length === 0);
  return (
    <>
      <Flex mbottom="10" direction="column">
        {canPurchaseFreeTrial && !canPurchaseUpgrade && (
          <Heading>
            Choose Your Plan & Enjoy {FREE_TRIAL_DURATION} days Free Trial
          </Heading>
        )}
        {canPurchaseFreeTrial && canPurchaseUpgrade && (
          <Heading>Choose the free trial plan Upgrade:</Heading>
        )}
        {!canPurchaseFreeTrial && canPurchaseUpgrade && (
          <Heading>Upgrade the plan that fits your needs</Heading>
        )}
        {!canPurchaseFreeTrial && !canPurchaseUpgrade && (
          <Heading>Choose Your plan</Heading>
        )}
      </Flex>
      {showToggle && (
        <Flex justify="center" mtop="12" mbottom="30" direction="column">
          <Flex>
            <PlanType active={checked}>Monthly</PlanType>
            <IOSSwitch
              sx={{ m: 1, color: '#5FA4FB' }}
              checked={checked}
              onChange={handleChange}
              disabled={canPurchaseUpgrade || isActiveYearlySubscription}
            />
            <PlanType active={!checked}>Yearly</PlanType>
          </Flex>
          {!checked && (
            <Flex style={{ position: 'relative' }}>
              <DiscountInfo>*save 20%</DiscountInfo>
              <Image
                width="18"
                height="17"
                src={DiscountArrow}
                style={{ position: 'absolute', left: 75, bottom: 8 }}
              />
            </Flex>
          )}
        </Flex>
      )}
      <Flex
        direction="column"
        justify={
          IsMobile() && showToggle && typeOfPlans.length > 1
            ? 'start'
            : 'center'
        }
      >
        {typeOfPlans.map((el: IPlan) => {
          const { name, id, prices, supported_features, subscription_cycle } =
            el;
          const { currency, price: current_price } = prices[0];

          const ribbon =
            partnerName === PARTNER.LUNA ? RibbonLunaIcon : RibbonIcon;

          const showWeeklyPrice = () => {
            const price =
              subscription_cycle === 12
                ? current_price / 52 / 100
                : current_price / 4 / 100;

            return Math.floor(price * 100) / 100; // Keeps two decimal places without rounding
          };
          return (
            <PlanCard key={id}>
              {subscription_cycle === 12 && (
                <ImgCntr src={ribbon} alt="Ribbon" />
              )}
              <FlexContainer>
                <Stack justifyContent="center" alignItems="center" mt={1}>
                  <PlanHeading theme={theme}>{name}</PlanHeading>
                  <PaymentContainer>
                    <span
                      style={{
                        fontSize: '32px',
                        fontWeight: 500,
                        color: theme.palette.text.primary,
                        marginRight: '10px'
                      }}
                    >{`${CURRENCY[currency]}${showWeeklyPrice()}`}</span>
                    <GreyText>/week</GreyText>
                  </PaymentContainer>

                  <Stack direction="row" alignItems="center">
                    <Typography
                      variant="h5"
                      sx={{
                        color: theme.palette.primary.main,
                        marginRight: 1,
                        fontSize: '18px'
                      }}
                    >{`${CURRENCY[currency]}${(current_price / 100).toFixed(
                      2
                    )}`}</Typography>

                    <GreyText>
                      {subscription_cycle === 1
                        ? 'billed monthly'
                        : 'billed annually'}
                    </GreyText>
                  </Stack>
                </Stack>

                <hr style={{ borderColor: '#FFF' }} />
                {supported_features.map((element: IFeature) => {
                  const icon = obj[element.id];
                  return (
                    <Flex align="center" key={element.id} mbottom="10">
                      <Image width="20" height="20" src={icon} />
                      <PlanDetailPoint>{element.description}</PlanDetailPoint>
                    </Flex>
                  );
                })}
              </FlexContainer>
            </PlanCard>
          );
        })}

        <Flex
          mtop="20"
          mbottom="20"
          align="flex-start"
          style={{
            fontSize: 14,
            fontWeight: 300,
            lineHeight: '18px',
            color: '#484C4F',
            width: '100%'
          }}
        >
          {canPurchaseFreeTrial && 'Your free trial begins immediately. '}
          Enjoy peace of mind with our premium features for your newborn.
        </Flex>

        <Flex
          mtop="10"
          mbottom="20"
          justify="center"
          style={{
            width: '100%'
          }}
        >
          <Button
            color="primary"
            variant="contained"
            sx={{
              outline: 'none',
              textTransform: 'none',
              '&:focus': {
                outline: 'none'
              }
            }}
            style={{
              color: '#fff',
              height: '52px',
              width: '100%',
              maxWidth: '300px',
              borderRadius: '44px',
              fontSize: '16px',
              fontWeight: '600',
              lineHeight: '24px'
            }}
            onClick={() => {
              setIsSubmiting(true);
              if (onPlanSelection) {
                onPlanSelection(typeOfPlans[0]);
              } else {
                handlePlanSelection(typeOfPlans[0]);
              }
            }}
            disabled={isSubmiting}
          >
            {canPurchaseFreeTrial && !canPurchaseUpgrade && 'Try For Free'}
            {canPurchaseUpgrade && 'Upgrade'}
            {!canPurchaseFreeTrial && !canPurchaseUpgrade && 'Buy Now'}
            {isSubmiting && (
              <CircularProgress
                style={{ height: '15px', width: '15px', marginLeft: '10px' }}
                color="inherit"
              />
            )}
          </Button>
        </Flex>

        {showSkipDialog && (
          <Dialog
            open={showSkipDialog}
            PaperProps={{
              style: {
                margin: '8px'
              }
            }}
            maxWidth="xs"
          >
            <Flex
              direction="column"
              gap="24px"
              style={{ padding: '24px 16px' }}
            >
              <Flex direction="column" align="start">
                <DialogHeading>Are you Sure?</DialogHeading>

                <DialogSubHeading>
                  {`Don't miss out on our special free trial offer. Start
                  monitoring and ensuring your baby's safety today!`}
                </DialogSubHeading>
              </Flex>

              <Flex justify="space-between" style={{ width: '100%' }}>
                <Button
                  color="primary"
                  sx={{
                    justifyContent: 'flex-start'
                  }}
                  variant="text"
                  size="small"
                  onClick={() => {
                    navigate('/skip-trial', {
                      state: {
                        isFreeTrial,
                        deviceId,
                        pairingFlow
                      }
                    });
                  }}
                >
                  Go without subscription
                </Button>
                <Button
                  color="primary"
                  sx={{
                    borderRadius: '25px',
                    borderWidth: '1.5px',
                    fontSize: '13px'
                  }}
                  variant="contained"
                  onClick={() => {
                    toggleShowSkipDialog(false);
                  }}
                >
                  Go back to Plans
                </Button>
              </Flex>
            </Flex>
          </Dialog>
        )}
      </Flex>
    </>
  );
}
